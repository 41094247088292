<template>
    <div class="ownerQuit">
<!--        <van-nav-bar title="业主退租" left-arrow @click-left="leftReturn"/>-->
        <rxNavBar :title="detailTitle"></rxNavBar>
        <div class="applyTime">申请时间：&nbsp;{{totalInfos.applicationTime}}</div>
        <div class="text">
            <div class="complaint_message">
                <div class="wait-approve">待审批</div>
                <div class="comlaint_site">
                    <div class="site">{{contractDetails.houseAddress}}</div>
                </div>
                <table  class="houseInfo">
                    <tr>
                        <td><div class="boldFont">编号：<span class="normalFont">{{contractDetails.contractCode}}</span></div></td>
                        <td><div class="boldFont">到期日：<span class="normalFont" style="color: #EB7B65;">{{totalInfos.oldContractEndTime}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont">{{type==2?'业主姓名':'租客姓名'}}：<span class="normalFont" >{{type==2?ownerContractInfo.ownerName:renterContractInfo.renterName}}</span></div></td>
                        <td><div class="boldFont">付款方式：<span class="normalFont" >{{totalInfos.oldPayTypeName}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont">租金：<span class="normalFont">{{type==2?ownerContractInfo.rentMoney:renterContractInfo.rentMoney}}元</span></div></td>
                        <td><div class="boldFont">押金：<span class="normalFont">{{type==2?ownerContractInfo.depositMoney:renterContractInfo.depositMoney}}元</span></div></td>
                    </tr>
                </table>
                <div>
                    <van-button plain type="primary" @click="gotoOldContractInfo">查看合同</van-button>
                </div>
            </div>
            <!--退房详情-->
            <div class="accept" >退房详情</div>
            <div class="complaint_message">
                <table  class="houseInfo">
                    <tr>
                        <td><div class="boldFont">正常退租：<span class="normalFont" style="color: #EB7B65;">{{historyInfo.isNormalReturn=='0'?'否':'是'}}</span></div></td>
                        <td><div class="boldFont">解除日期：<span class="normalFont">{{totalInfos.estimatedDepartureTime}}</span></div></td>
                    </tr>
                    <tr>
                        <td v-if="type==2"><div class="boldFont">违约方：<span class="normalFont" >{{!historyInfo.defaultingParty?'暂无':historyInfo.defaultingParty=='1'?'业主':'平台'}}</span></div></td>
                        <td v-if="type==1"><div class="boldFont">违约方：<span class="normalFont" >{{!historyInfo.defaultingParty?'暂无':historyInfo.defaultingParty=='1'?'租客':'平台'}}</span></div></td>
                        <td><div class="boldFont">有责：<span class="normalFont" style="color: #EB7B65;">{{historyInfo.defaultingType=='0'?'无':'有'}}</span></div></td>
                    </tr>
                </table>
                <div class="relieve">
                    <tr>
                        <td><div class="boldFont2" >解除类型：<span class="normalFont">{{totalInfos.contractTerminationTypeVal}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont2" >解除原因：<span class="normalFont1">{{totalInfos.contractTerminationReasonVal?totalInfos.contractTerminationReasonVal:'暂无'}}</span></div></td>
                    </tr>
                </div>
            </div>
            <!--退款详情-->
            <div class="accept"  >退款详情</div>
            <div class="complaint_message">
                <table  class="houseInfo" v-if="type==1">
                    <tr>
                        <td><div class="boldFont1">剩余租金：<span class="normalFont3" >{{historyInfo.restRentMoney == undefined ? '暂无' : historyInfo.restRentMoney+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">剩余水电：<span class="normalFont3" > {{historyInfo.restWaterElectric == undefined ? '暂无' : historyInfo.restWaterElectric+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">剩余押金：<span class="normalFont3" > {{historyInfo.surplusRent == undefined ? '暂无' : historyInfo.surplusRent+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">违约金：<span class="normalFont3" >{{historyInfo.falsify == undefined ? '暂无' : historyInfo.falsify+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">滞纳金：<span class="normalFont3" >{{historyInfo.lateFee == undefined ? '暂无' : historyInfo.lateFee+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">装修赔偿：<span class="normalFont3" >{{historyInfo.payRepair == undefined ? '暂无' : historyInfo.payRepair+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">预留水电费：<span class="normalFont3" >{{historyInfo.reserveWaterElectric == undefined ? '暂无' : historyInfo.reserveWaterElectric+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">利息：<span class="normalFont3" >{{historyInfo.interest == undefined ? '暂无' : historyInfo.interest+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1" >租客是否补款<span style="color: #EB7B65;">{{historyInfo.isUserPayed=='0'?'(无需补款)':'(需补款)'}}</span>：<span class="normalFont3" style="color: #EB7B65;">{{historyInfo.isUserPayed=='0'?'否':historyInfo.userPayedMoney == undefined ? '暂无' : historyInfo.userPayedMoney+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">实际违约金：<span class="normalFont3" >{{historyInfo.realFalsify == undefined ? '暂无' : historyInfo.realFalsify+'元'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">应退总额：<span class="normalFont3" >{{historyInfo.totalRefunded == undefined ? '暂无' : historyInfo.totalRefunded+'元'}}</span></div></td>
                    </tr>
                </table>
                <table  class="houseInfo" v-if="type==2">
                    <tr>
                        <td><div class="boldFont1">业主退回房租：<span class="normalFont3" >{{historyInfo.restRentMoney != undefined?historyInfo.restRentMoney+'元':'暂无'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">支付业主房租：<span class="normalFont3" > {{historyInfo.surplusRent != undefined?historyInfo.surplusRent+'元':'暂无'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">违约金：<span class="normalFont3" >{{historyInfo.realFalsify != undefined?historyInfo.realFalsify+'元':'暂无'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">装修赔偿：<span class="normalFont3" >{{historyInfo.payRepair != undefined?historyInfo.payRepair+'元':'暂无'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">公司退回总额：<span class="normalFont3" >{{historyInfo.userTotalPay != undefined?historyInfo.userTotalPay+'元':'暂无'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont1">业主退回总额：<span class="normalFont3" >{{historyInfo.realBreakMoney != undefined?historyInfo.realBreakMoney+'元':'暂无'}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont-remarks">补充说明：<span class="normalFont-remarks" >{{historyInfo.remarks}}</span></div></td>
                    </tr>
                </table>
                <!--                <table  class="houseInfo">-->
                <!--                    <tr>-->
                <!--                        <td><div class="boldFont1" >退回总额<span style="color: #EB7B65;">{{historyInfo.isUserPayed=='0'?'':'(需补款)'}}</span>：<span class="normalFont7" style="color: #EB7B65;">{{historyInfo.isUserPayed=='0'?historyInfo.userTotalPay:historyInfo.UserPayedMoney}}元</span></div></td>-->
                <!--                    </tr>-->
                <!--                </table>-->
                <div>
                    <van-button class="look" plain type="primary" @click="openElectricContract">查看解除协议</van-button>
                </div>
            </div>
            <div v-if="historyType=='换房' || historyType=='转租'">
            <div class="accept"  >新房信息</div>
            <div class="complaint_message">
                <div class="comlaint_site">
                    <div class="site">{{totalInfos.newContractAddress}}</div>
                </div>
                <table  class="houseInfo">
                    <tr>
                        <td><div class="boldFont">合同编号：<span class="normalFont">{{totalInfos.newContractCode}}</span></div></td>
                        <td><div class="boldFont">付款方式：<span class="normalFont" >{{totalInfos.newPayTypeName}}</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont">租客姓名：<span class="normalFont" >{{newMiddlecontract.renterName}}</span></div></td>
                        <td><div class="boldFont">押金：<span class="normalFont">{{newMiddlecontract.depositMoney}}元</span></div></td>
                    </tr>
                    <tr>
                        <td><div class="boldFont">租金：<span class="normalFont">{{newMiddlecontract.rentMoney}}元</span></div></td>
                    </tr>
                    <tr>
                        <td colspan="2"><div class="boldFont-1">租期：<span class="normalFont" style="color: #EB7B65;">{{totalInfos.newContractBeginTime+' 至 '+totalInfos.newContractEndTime}}</span></div></td>
                    </tr>
                </table>
                <div>
                    <van-button plain type="primary" @click="gotoNewContractInfo">查看合同</van-button>
                </div>
            </div>
            </div>
            <!--审核反馈-->
            <div class="accept"  >审核反馈</div>
        </div>
        <div class="normalBlock" >
            <span class="maker" :class="{'orangeColor':(isPass||isReject)}">• </span>
            <span class="blockTitle">审核结果</span>
            <div class="rightText">
                <span class="passText" :class="{'choosedStyle':isPass}" @click="choosePass">通过</span>
                <span class="content-divide" > | </span>
                <span class="rejectText" :class="{'choosedStyle':isReject}" @click="chooseReject">驳回</span>
            </div>
        </div>
        <!--<div class="normalBlock" v-show="isPass" >
            <span class="maker" :class="{'orangeColor':(guidingPrice!='')}">• </span>
            <span class="blockTitle">指导价</span>
            <span class="content-divide">|</span>
            <input  type="number" class="inputPrice" onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''" v-model="guidingPrice" >
            <span class="priceUnit">元</span>
        </div>-->

        <div id="addressPanel" class="part-inputpart-row" v-show="isReject" @click="clickAddressShow">
            <span class="maker" :class="{'orangeColor':isReasonSelect}" >• </span>
            <span class="part-inputpart-row-header">原因</span>
            <span class="content-divide">|</span>
            <span class="part-inputpart-row-graytext" :class="isReasonSelect ? 'part-inputpart-row-normaltext':'' ">{{rejectReason}}</span>
            <img class="part-inputpart-row-right" :class=" isReasonShow ? 'part-inputpart-row-right-upArrow' : 'part-inputpart-row-right-downArrow' " src="../../../assets/images/triangle.png">
        </div>
        <div class="reject" v-show="isReject">
            驳回次数：{{lastRefuseCount?lastRefuseCount:'暂无'}}&nbsp;|&nbsp;上次驳回原因：{{lastRefuseReason?lastRefuseReason:'暂无'}}
        </div>
<!--        <div>-->
<!--            <div class="part-inputpart part-inputpart-dropDownList" v-if="isReasonShow">-->
<!--                <div class="part-inputpart-dropDownList-option" :class="number===index ? 'part-inputpart-dropDownList-option-selected' : '' "-->
<!--                     v-for="(item,index) in checkRefuseReasonList" :key="index" @click="selectAddressOption(index)">{{item.dictionaryTitle}}</div>-->
<!--            </div>-->
<!--        </div>-->
        <van-popup v-model="isReasonShow" position="bottom">
            <van-picker
                    show-toolbar
                    :columns="checkRefuseReasonList"
                    @cancel="isReasonShow = false"
                    value-key="dictionaryTitle"
                    @confirm="selectAddressOption"
            />
        </van-popup>
        <div class="displayBlock"  >
            <div><span class="suggestTitle">审批意见</span></div>
            <div><textarea  class="textArea" v-model="approvalOpinion"></textarea></div>
        </div>
        <van-button  class="saveButton_Disable" :class="{'saveButton_Enable': isPass || (isReject && isReasonSelect)}"
                     :disabled=" !(isPass || (isReject && isReasonSelect))" @click="save" >保存</van-button>


    </div>
</template>

<script>
    import { NavBar,Search, DropdownMenu, DropdownItem ,Button,Toast,Picker,
        Popup,} from 'vant';
    import {
        approvalContractDetail,
        queryBaseData,
        renterRetreatDetails,
        chanageContractAgree,
        chanageContractRefuse,
        findElectronicContractUrl
    } from "../../../getData/getData";
    import {checkAndroid, checkIOS, getStaffId,openInWebview, responseUtil} from "../../../libs/rongxunUtil";
    import rxNavBar from "../../../components/rongxun/rx-navBar/navBar";
    export default {
        components: {
            [Button.name]: Button,
            [NavBar .name]:NavBar,
            [Search .name]:Search,
            [DropdownMenu .name]:DropdownMenu,
            [DropdownItem .name]:DropdownItem,
            [Toast.name]:Toast,
            [Popup.name]:Popup,
            [Picker.name]:Picker,
            rxNavBar
        },
        name: "ownerQuit",
        methods: {
            //返回上一层
            leftReturn() {
                this.$router.go(-1)
            },
            //审批操作保存接口
            save(){
                if(this.isPass){
                    //审批同意接口
                    let that = this
                    let initData={}
                    initData.id = that.approvalFinishId
                    initData.approvalOpinion = that.approvalOpinion
                    initData.staff_id = getStaffId()
                    chanageContractAgree(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            responseUtil.alertMsg(that,'审批成功')
                            that.$router.go(-1)
                        })
                    })
                } else {
                    //审批拒绝接口
                    //操作给出提示,重新回到列表页面
                    //审批同意接口
                    let that = this
                    let initData={}
                    initData.id = that.approvalFinishId
                    initData.approvalOpinion = that.approvalOpinion
                    initData.refuseReason_id = that.refuseReasonId
                    initData.staff_id = getStaffId()
                    chanageContractRefuse(initData).then(function (response) {
                        responseUtil.dealResponse(that, response, () => {
                            responseUtil.alertMsg(that,'审批拒绝')
                            that.$router.go(-1)

                        })
                    })
                }
            },
            //业主或者租客解除合同信息初始化加载
            approvalContractDetail(){
                //先初始化字典信息
                //this.initReasonAndTypeData()
                let that = this
                let initData={}
                initData.contractHistoryId = that.ch_id
                approvalContractDetail(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.totalInfos = response.data.data
                        if(that.type==2){
                            //业主合同信息
                            that.ownerContractInfo = that.totalInfos.ownercontract
                            let historyContractType = response.data.data.contracthistory.contractHistoryHistoryType
                            //根据type和contractHistoryHistoryType动态改变标题
                            that.checkTitle(that.type,historyContractType)
                        } else {
                            //租客合同信息
                            that.renterContractInfo = that.totalInfos.rentercontract
                            //租客合同信息
                            that.renterContractInfo = that.totalInfos.rentercontract
                            let historyContractType = response.data.data.contracthistory.contractHistoryHistoryType
                            //根据type和contractHistoryHistoryType动态改变标题
                            that.checkTitle(that.type,historyContractType)
                        }
                        that.historyInfo = that.totalInfos.contracthistory
                        that.contractDetails = that.totalInfos.contract
                        that.newMiddlecontract = that.totalInfos.newMiddlecontract
                        that.newContract = that.totalInfos.newContract
                    })
                })
            },
            //动态改变导航标题方法
            checkTitle(type,historyContractType){
                let firstText= '';
                let secondText = '';
                if(type == 2){
                    firstText = '业主';
                }else{
                    firstText = '租客';
                }
                if(historyContractType == 1){
                    secondText = '续签';
                }else if(historyContractType == 2 || historyContractType == 3){
                    secondText = '退房';
                }else if(historyContractType == 4){
                    secondText = '转租';
                }else if(historyContractType == 5){
                    secondText = '换房';
                }
                this.detailTitle = firstText + secondText + '申请';
            },
            //初始化原因和拒绝类型字典项
            initReasonAndTypeData(){
                let that = this
                let initData={}
                initData.dbName = ["contractTerminationReason","contractTerminationType"]
                queryBaseData(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        that.refuseTypeList = response.data.data.contractTerminationType
                        that.refuseReasonList = response.data.data.contractTerminationReason
                    })
                })
            },
            //跳转原合同详情页面
            gotoOldContractInfo(){
                this.$router.push({
                    name:'contractDetail',
                    query:{
                        contractId:this.contractId,
                        type:this.type,
                        ownerOrRenterContractId:this.type==2?this.ownerContractInfo.id:this.renterContractInfo.id,
                        historyType:this.historyType,
                        contractTypeVal:this.getContractVal(this.contractStatus),
                        contractStatus:this.contractStatus,
                        mode:'1',
                        contractHistoryId:this.ch_id ||''
                    }
                });
            },
            //跳转新合同详情页面
            gotoNewContractInfo(){
                this.$router.push({
                    name:'contractDetail',
                    query:{
                        contractId:this.newContract.id,
                        type:this.type,
                        ownerOrRenterContractId:this.newMiddlecontract.id,
                        historyType:this.historyType,
                        contractTypeVal:this.getContractVal(this.newContract.contractStatus),
                        contractStatus:this.newContract.contractStatus,
                        mode:'1',
                        contractHistoryId:''
                    }
                });
            },
            //初始化审核状态信息
            renterRetreatDetails(){
                let that = this
                let initData={}
                initData.id = that.approvalFinishId
                renterRetreatDetails(initData).then(function (response) {
                    responseUtil.dealResponse(that, response, () => {
                        //console.log(response.data.data.refuseReasonList)
                        that.applayApproveInfo = response.data.data.item
                        let list = response.data.data.refuseReasonList
                        if(list){
                            that.checkRefuseReasonList = list
                        }
                    })
                })
            },
            getContractVal(status){
                if(status==4){
                    return '已解除'
                } else if(status==5){
                    return '待签字'
                } else if(status==7){
                    return '已作废'
                } else {
                    return '租期中'
                }
            },
            //查看电子合同
            openElectricContract(){
                let that = this
                if (that.contractDetails.electricRelieveContract_id) {
                    let queryData = {};
                    queryData.contractId = that.contractDetails.electricRelieveContract_id;
                    findElectronicContractUrl(queryData).then(function(response) {
                        responseUtil.dealResponse(that, response, () => {
                            if (openInWebview()) {
                                if(checkAndroid()){
                                    window.open(response.data.data.url);
                                }else if(checkIOS()){
                                    window.location.href =response.data.data.url;
                                }
                            }
                        })
                    })
                } else {
                    responseUtil.alertMsg(that, '没有电子合同！');
                }
            },
            choosePass(){
                this.isPass = true;
                this.isReject = false;
            },
            chooseReject(){
                this.isReject = true;
                this.isPass = false;
            },
            selectAddressOption(value,index){
                this.number = index
                this.rejectReason = this.checkRefuseReasonList[index].dictionaryTitle
                this.refuseReasonId = this.checkRefuseReasonList[index].id
                //console.log(this.refuseReasonId)
                this.isReasonShow = false
                this.isReasonSelect = true
            },
            clickAddressShow() {
                this.isReasonShow = !this.isReasonShow;
            },
        },
        created() {
            this.contractId = this.$route.query.contractId
            this.ch_id = this.$route.query.ch_id
            this.id = this.$route.query.id
            this.lastRefuseCount = this.$route.query.lastRefuseCount
            this.lastRefuseReason = this.$route.query.lastRefuseReason
            this.type = this.$route.query.type
            this.historyType = this.$route.query.historyType
            this.contractTypeVal = this.$route.query.contractTypeVal
            this.contractStatus = this.$route.query.contractStatus
            this.mode = this.$route.query.mode
            this.approvalFinishId = this.$route.query.approvalFinishId
        },
        mounted(){
            this.approvalContractDetail()
            this.renterRetreatDetails()
            //this.initReasonAndTypeData()
        },
        data() {
            return {
                detailTitle: '',  //导航标题
                totalInfos:'',//总的信息
                ownerContractInfo:'',//业主合同信息
                renterContractInfo:'',//租客合同信息
                historyInfo:'',//合同历史信息
                contractDetails:'',//合同信息
                newContract:'',//新签的合同
                newMiddlecontract:'',//新签的(租客或者业主)合同
                number:-1,
                rejectReason:'',
                isReasonSelect: false,
                isPass:false,
                isReject:false,
                isSelect:'',
                isReasonShow:false,
                guidingPrice:"",
                //拒绝理由
                refuseReasonList:[],
                refuseTypeList:[],
                refuseType:'',
                refuseReason:'',
                contractInfo:'',
                checkRefuseReasonList:[],//审核拒绝原因
                applayApproveInfo:'',//申请详情
                approvalOpinion:'',//审批意见
                refuseReasonId:'',//拒绝理由的id
                dataList: {},
                contractId:'',
                ch_id:'',
                id:'',
                lastRefuseCount:'',
                lastRefuseReason:'',
                type:'',
                historyType:'',
                contractTypeVal:'',
                contractStatus:'',
                mode:'',
                approvalFinishId:''
            }
        }
    }
</script>

<style scoped>
    .ownerQuit{
        overflow: hidden;
        width: 100%;
    }
    .relieve{
        padding: 20px 0px 45px 15px;
    }
    .reject{
        padding-left: 30px;
        padding-top: 6px;
        width: 315px;
        height: 34px;
        color: rgba(153, 153, 153, 1);
        font-size: 12px;
        text-align: left;
        font-family: PingFangSC-Regular;
    }
    .look{
        width: 120px;
        margin-left: 210px;
    }
    .wait-approve{
        width: 46px;
        height: 14px;
        line-height: 14px;
        font-size: 12px;
        text-align: center;
        padding-top: 2px;
        color: white;
        border-radius: 8px 0;
        background: linear-gradient(to right,#ffb96f,#ff6d44);
    }
    .applyTime{
        font-size: 12px;
        text-align: right;
        color: #999999;
        padding-right: 15px;
        margin-top: 16px;
        margin-bottom: 6px;
    }
    .site{

    }
    .comlaint_site{
        display: flex;
        height: 55px;
        padding: 5px 0px 10px 15px;
        font-size: 17px;
        border-bottom: 1px solid #EFEFEF ;
        font-weight: bold;
    }
    .complaint_message{
        width: 345px;
        border-radius: 8px;
        background-color: #FFFFFF;
    }
    .text{
        margin-left: 15px;
    }
    .accept{
        height: 17px;
        color: rgba(255, 93, 59, 1);
        font-size: 12px;
        margin-top: 30px;
        font-weight: bold;
        margin-bottom: 5px;
        font-family: PingFangSC-Semibold;
    }
    .houseInfo{
        width:345px;
        padding: 15px 10px 15px 10px;
        border-bottom: 1px solid #EFEFEF ;
    }

    .boldFont{
        font-size: 14px;
        font-weight: bold;
        width: 157.5px;
        height: 24px;
    }
    .boldFont-1{
        font-size: 14px;
        font-weight: bold;
        height: 24px;
    }
    .boldFont1{
        font-size: 14px;
        font-weight: bold;
        width: 311px;
        height: 24px;
    }
    .boldFont2{
        font-size: 14px;
        font-weight: bold;
        width: 315px;
        /*height: 24px;*/
    }
    .normalFont{
        font-size: 13px;
        font-weight: normal;
        color: #999;
    }
    .normalFont1{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        line-height: 25px;
    }
    .normalFont8{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        padding-left: 190px;

    }
    .normalFont2{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        padding-left: 186px;
    }
    .normalFont3{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        float: right;
    }
    .boldFont-remarks{
        font-size: 14px;
        font-weight: bold;
        width: 309px;
        /*height: 24px;*/
        /*overflow: hidden;*/
        /*text-overflow: ellipsis;*/
        /*white-space: nowrap;*/
    }
    .normalFont-remarks{
        font-size: 13px;
        font-weight: normal;
        color: #999;
    }
    .normalFont4{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        padding-left: 190px;
    }
    .normalFont5{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        padding-left: 204px;
    }
    .normalFont6{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        padding-left: 218px;
    }
    .normalFont7{
        font-size: 13px;
        font-weight: normal;
        color: #999;
        padding-left: 125px;
    }






    .part-inputpart {
        display: flex;
        flex-direction: column;
        white-space: nowrap;
        overflow: hidden;
        font-size: 15px;
        border-radius: 8px;
    }


    .part-inputpart-dropDownList{
        width: 90%;
        position: absolute;
        left: 19px;
        z-index: 99;
        margin-top: -50px;

    }

    .part-inputpart-dropDownList-option{
        padding: 0;
        margin: 0;
        text-align: center;
        line-height: 30px;
        background-color: #f5f5f5;
        font-size: 12px;
        border-bottom: 1px white solid;
        color: #999999;
    }

    .part-inputpart-dropDownList-option-selected{
        color: #ff5d3b;
    }

    .part-inputpart-row-right-upArrow{
        width: 9px;
        transform: rotateX(180deg);
    }

    .part-inputpart-row-header{
        font-weight: bold;
        font-size: 15px;
        width: 50px;


    }


    .part-inputpart-row-graytext {
        color: #d8d8d8;
        width: 100%;
        font-size: 15px;
    }


    .part-inputpart-row-normaltext{
        color: black;
    }
    .part-inputpart-row-right{
        float: right;
        margin-right: 30px;
    }
    .part-inputpart-row-right-downArrow{
        width: 9px;
    }
    .part-inputpart-row {
        position: relative;
        display: flex;
        flex-direction: row;
        align-items: center;
        height: 50px;
        background-color: white;
        border-radius: 9px;
        width:345px;
        margin: 14px auto;
    }

    .content-divide {
        font-size: 14px;
        color: #efefef;
        margin:0 10px;
    }


    .suggestTitle{
        margin-left: 14px;
        margin-top:12px;
        float: left;
        font-size: 15px;
        font-weight: bold;
        display: block;
    }
    .textArea{
        resize: none;
        padding: 10px;
        margin:39px auto 20px;
        height: 100px;
        width: 290px;
        display: block;
        font-size: 14px;
        border-radius: 8px;
        border-style: none;
        background-color: #FAFAFA ;

    }

    .priceUnit{
        color: rgba(153, 153, 153, 0.45);
        font-size: 14px;
        float: right;
        padding-right: 31px;
    }
    .inputPrice{
        color:#ff3c00;
        width: 100%;
        height: 28px;
        line-height: 28px;
        font-size: 15px;
    //font-weight: bold;
        border: none;
    }
    .passText{
        flex: 1;
        padding-right: 7px;
    }
    .rejectText{
        padding-left: 7px;
        padding-right: 33px;

    }
    .rightText{
        margin-left:auto;
        font-size: 15px;
        color: #999999;
    }

    .maker{
        color: #999999;
        float: left;
        padding-left: 14px;
        padding-right: 8px;
        font-size: 28px;
    }
    .wait-approve{
        width: 46px;
        height: 14px;
        line-height: 14px;
        font-size: 12px;
        text-align: center;
        padding-top: 2px;
        color: white;
        border-radius: 8px 0;
        background: linear-gradient(to right,#ffb96f,#ff6d44);
    }
    .orangeColor{
        color: #ff3c00;
    }
    .choosedStyle{
        color: #ff3c00;
        font-weight: bold;
    }
    .blockTitle{
        overflow: hidden;
        float: left;
        font-size: 15px;
        font-weight: bold;
        width: 27%;
    }

    .descTitle{
        height: 25px;
        padding-top: 14px;
        padding-left: 14px;
        padding-right: 20px;
    }
    .desc-Text{
        height: auto;
        padding-left: 14px;
        padding-bottom: 17px;
        padding-right: 20px;
    }
    .applyTime{
        font-size: 12px;
        text-align: right;
        color: #999999;
        padding-right: 15px;
        margin-top: 16px;
        margin-bottom: 6px;
    }


    .saveButton_Disable{

        /*background: linear-gradient(to right, rgba(184, 184, 184, 0.2), #B8B8B8 20%) repeat scroll 0% 0%;*/
        background-color: rgba(184, 184, 184, 0.2);
        width: 345px;
        height: 45px;
        border-radius: 8px;
        margin:30px 0px 40px 15px;
        font-size: 18px;
        color: white;
        line-height: 45px;
        text-align: center;
    }
    .saveButton_Enable{
        background: linear-gradient(to right, #fdc07f 0px, #f86513 100%) repeat scroll 0% 0%;

    }
    .displayBlock {
    //position: relative;
        overflow: hidden;
        background-color: white;
        border-radius: 9px;
        width:345px;
        height: auto;
        margin: 15px auto;
    }
    .normalBlock{
        display: flex;
        flex-direction: row;
        align-items: center;
        overflow: hidden;
        position: relative;
        background-color: white;
        border-radius: 9px;
        width:345px;
        line-height: 1rem;
        height: 50px;
        margin: 5px auto 14px;
    }



</style>
